import React, { useState, useContext} from 'react'
import { Button, Container, Grid, TextField } from '@material-ui/core';
import '../style/login.css'
import { makeStyles } from '@material-ui/core/styles';
import * as AuthRouter from '../routing/auth.json'
import {http} from '../helpers/http';
import Snackbar from '../components/snackbar';
import {AuthContext} from "../contexts/auth.context";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
require('dotenv').config();

const useStyles = makeStyles((theme)=>({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

export default function Login() {

    let authContext = useContext(AuthContext)
    const [isLoading, setisLoading] = useState(false)

    const {
        isAuth,
        auth
        } = authContext
    
    let initCreds = {
        username : null,
        password : null
    }

    let initSnackBar = {
        open : false, message : null, state : 'error'
    }

    const [creds, setcreds] = useState(initCreds)
    const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

    const classes = useStyles();

    const sendLogin = async() =>{
        setisLoading(true)
        setsnackbarstatuss(initSnackBar)
        const url = `${process.env.REACT_APP_API_BASE_URL}${AuthRouter.LOGIN}`
        const method = 'POST'
        const body = {
            userName : creds.username,
            password : creds.password
        }
        let res = await http({url , method, body})
        if(res?.response?.success === true){
            localStorage.setItem('token', res?.response?.token);
            auth(res?.response?.token)
        }
        setisLoading(false)
        setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    }

    return (
        <Grid
        spacing={0}
        align="center"
        justify="center"
        direction="row"
        >
            <Container>
            <Backdrop open={isLoading} className={classes.backdrop}>
            <CircularProgress color="primary" />
            </Backdrop>
                <form className="form">
                <img src="/logo.png" alt="image" className="formLogo"/>
                <TextField id="standard-basic" label="Email" 
                                value={creds.username}
                                onChange={(e)=>{setcreds({...creds, username: e.target.value})}}
                                />
                <TextField id="standard-basic" label="Password" type="password"
                                value={creds.password}
                                onChange={(e)=>{setcreds({...creds, password: e.target.value})}}
                                />
                <Button type="button" color="primary" variant="contained"  className="form__custom-button" color="primary"
                                onClick={(e)=>{sendLogin()}}
                                >
                    Log in
                </Button>
                </form>
            </Container>
            <Snackbar open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
        </Grid>

    )
}
