import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import * as AdminRouter from '../routing/admin.json'
import { useForm } from 'react-hook-form';
import Snackbar from '../components/snackbar';
import {AuthContext} from '../contexts/auth.context'
import {http} from '../helpers/http'


// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
let roles = []
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


export default function UpdateAdminModal({openUpdateComand, hundleOpenUpdateModel, fetchData, adminToUpdate, setadminToUpdate}) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openUpdateComand)
  }, [openUpdateComand])


  const onSubmit =async(data) => {

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.UPDATE_ADMIN}`
    const method = 'POST'
    const body = {first_name, last_name, permissions : permissionsList, _id : adminToUpdate._id}
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    console.log(res)
  };

  useEffect(() => {
    setpermissionsList(adminToUpdate?.permissions?? [])
  }, [openUpdateComand])

  let authContext = useContext(AuthContext)
  let {profile} = authContext
  if(profile){
    roles = profile.permissions
  }

  
//  useEffect(() => {
 //   setOpen(adminToUpdate)
//}, [adminToUpdate])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setpermissionsList(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setpermissionsList(value);
  };

  const handleClose = () => {
    setadminToUpdate(null)
    hundleOpenUpdateModel()
    setpermissionsList([])
    setOpen(false);
  };

console.log(adminToUpdate)
const [permissionsList, setpermissionsList] = React.useState(adminToUpdate?.permissions?? []);
const [first_name, setfirst_name] = useState(adminToUpdate?.first_name?? '')
const [last_name, setlast_name] = useState(adminToUpdate?.last_name ?? '')

useEffect(() => {
  setfirst_name(adminToUpdate?.first_name)
  setlast_name(adminToUpdate?.last_name)
  setpermissionsList(adminToUpdate?.permissions?? [])
}, [adminToUpdate])

return (
    <div>
 <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Admin</DialogTitle>
        <DialogContent>
          <form onSubmit={()=>onSubmit()}>
          <DialogContentText>
            To update the admin, please enter your following informations.
          </DialogContentText>
            <TextField
            autoFocus
            margin="dense"
            id="first_name"
            onChange={(e)=>setfirst_name(e.target.value)}
            label="First Name"
            defaultValue={adminToUpdate?.first_name}
            type="text"
            fullWidth
          />
         <TextField
            autoFocus
            margin="dense"
            id="last_name"
            defaultValue={adminToUpdate?.last_name}
            onChange={(e)=>setlast_name(e.target.value)}
            label="Last Name"
            type="text"
            fullWidth
          />
    <FormControl fullWidth>
        <InputLabel id="demo-mutiple-chip-label" fullWidth>Permissions</InputLabel>
        <Select
        fullWidth
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          defaultValue={permissionsList}
        onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {roles.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
    </FormControl>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>onSubmit()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
