import React, { useEffect } from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackbar({state, message, open}) {

        const [inopen, setOpen] = React.useState(open);
    
        useEffect(() => {
            setOpen(open)
        }, [open])

        const handleClick = () => {
        setOpen(true);
        };
    
        const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen(false);
        };


    return (
        <Snackbar open={inopen} autoHideDuration={3000} onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert onClose={handleClose} severity={state?? 'error'} className="errorMessage">
        {message ?? 'Something went wrong.'}
        </Alert>
    </Snackbar>
    )
}
