import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Container } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as AdminRouter from '../routing/admin.json'
import {http} from '../helpers/http'
import Snackbar from '../components/snackbar'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Backdrop from '@material-ui/core/Backdrop';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkIcon from '@material-ui/icons/Link';
import {
    Link
} from "react-router-dom";
import CreateAdminModal from './createAdminModal';
import Switch from '@material-ui/core/Switch';
import UpdateAdminModal from './updateAdminModal';
import LinkGhlAdminModal from './linkGhlAdminModal';
import {AuthContext} from '../contexts/auth.context'
import getPermissions from '../permissions.ts'


const columns = [
  { id: 'id', label: '_Id', minWidth: 170 },
  { id: 'first_name', label: 'first_name', minWidth: 100 },
  { id: 'last_name', label: 'last_name', minWidth: 100 },
  { id: 'email', label: 'email', minWidth: 100 },
  { id: 'isActive', label: 'Activated', minWidth: 100 },
  {
    id: 'Actions',
    label: 'Actions',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme)=>({
  head: {
    backgroundColor: "#3f51b54d",
    color:"black",
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  addButton : {
  margin : '20px'
  },
  header : {
    backgroundColor : 'white',
    flex: 1,
    padding : '5px',
    marginBottom : '30px',
    borderRadius : '5px'
  },
  headerText : {
    fontWeight : 'bold',
    fontSize : '20px'
  }
}));

export default function AdminsDatatableC(props) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

let authContext = useContext(AuthContext)

const {
  canDo
} = authContext

const activateAdmin = async(id,isActive) =>{
  setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.ACTIVATE_ADMIN}`
    const method = 'POST'
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const body = {
      id,
      isActive
    }
    let res = await http({url , method, headers, body})
    fetchData()
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    console.log(res)
}

const permissions =  getPermissions()
const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)
const [isLoading, setisLoading] = useState(false)

    const fetchData = async() =>{
      setisLoading(true)
      setsnackbarstatuss(initSnackBar)
        const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.ALL_ADMIN}`
        const method = 'POST'
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        let res = await http({url , method, headers})
        if(res?.response?.success){
            setadminsData(res.response.data)
        }
        setisLoading(false)
        setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
        console.log(res)
    }

    const hundleOpenCreateModel = () => {
        setopenComand(!openComand)
    }

    const hundleOpenUpdateModel = () => {
      setopenUpdateComand(!openUpdateComand)
  }
    
  const hundleopenLinkGhlAdminModal = () => {
    setopenLinkGhlAdminModal(!openLinkGhlAdminModal)
}
  

    const adminsDataInit = []
    const [adminsData, setadminsData] = useState(adminsDataInit)
    const [openComand, setopenComand] = useState(false)
    const [openUpdateComand, setopenUpdateComand] = useState(false)
    const [openLinkGhlAdminModal, setopenLinkGhlAdminModal] = useState(false)
    const [adminToUpdate, setadminToUpdate] = useState(null)

    useEffect(() => {
    fetchData()
    }, [])

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className={classes.header}>
      <p className={classes.headerText} >
      Admins
      </p>
    </div>

      <LinkGhlAdminModal
      openLinkGhlAdminModal={openLinkGhlAdminModal}
      fetchData={fetchData} 
      hundleopenLinkGhlAdminModal={hundleopenLinkGhlAdminModal}
      adminToUpdate={adminToUpdate}
      setadminToUpdate={setadminToUpdate}
      ></LinkGhlAdminModal>

      <UpdateAdminModal 
      adminToUpdate={adminToUpdate} 
      fetchData={fetchData} 
      openUpdateComand={openUpdateComand} 
      hundleOpenUpdateModel={hundleOpenUpdateModel} 
      setadminToUpdate={setadminToUpdate}>
      </UpdateAdminModal>
          <Backdrop open={isLoading} className={classes.backdrop}>
          <CircularProgress color="primary" />
          </Backdrop>
        <CreateAdminModal fetchData={fetchData} openComand={openComand} hundleOpenCreateModel={hundleOpenCreateModel}></CreateAdminModal>
        <Paper className={classes.root}>

        {
                canDo(permissions.CREATE_ADMIN)  && <Button type="button" color="primary" variant="contained"  className="form__custom-button" color="primary" className={classes.addButton}
                                onClick={(e)=>{hundleOpenCreateModel()}}
                                >
                    Add new admin
                </Button>
        }

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <TableCell
                className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {adminsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                     <TableCell >
                         {row._id}
                      </TableCell>
                      <TableCell >
                         {row.first_name}
                      </TableCell>
                      <TableCell >
                         {row.last_name}
                      </TableCell>
                      <TableCell >
                         {row.email}
                      </TableCell>

                      <TableCell >
                      <Switch
                        checked={row.isActive}
                        onClick={()=>activateAdmin(row._id, !row.isActive)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      </TableCell>
                      <TableCell >

              {
                canDo(permissions.UPDATE_ADMIN)  &&   <IconButton  IconButton color="primary" aria-label="upload picture" component="span"
                            onClick={(e)=>{
                              setadminToUpdate(row)
                              hundleOpenUpdateModel()
                            }}
                            >
                          <EditRoundedIcon />
                        </IconButton>
                }

                {
                canDo(permissions.UPDATE_GHL_ADMIN)  && <IconButton color="primary" aria-label="upload picture" component="span"
                            onClick={(e)=>{
                              setadminToUpdate(row)
                              hundleopenLinkGhlAdminModal()
                            }}
                            >
                          <LinkIcon />
                        </IconButton>
                  }
                      </TableCell>

                  {/* {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })} */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={adminsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    <Snackbar key="createAdmin" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </>
  );
}
