import AuthContextProvider from "./contexts/auth.context";
import FiltersContextProvider from "./contexts/filters.context"
import Root from './screens/root';

export default function App() {

  return (
    <AuthContextProvider>
      <FiltersContextProvider>
        <Root></Root>
      </FiltersContextProvider>
    </AuthContextProvider>
  );
}