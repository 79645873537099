import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import * as AgencyRouter from '../routing/agency.json'
import { useForm } from 'react-hook-form';
import Snackbar from '../components/snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {http} from '../helpers/http'


export default function ChangeDomainModal({openChangeDomainModal, hundleOpenChangeDomainModel, fetchData, contactId}) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = useState(false)
    const [domain, setisDomain] = useState(null)


  useEffect(() => {
    setOpen(openChangeDomainModal)
  }, [openChangeDomainModal])


  const onSubmit =async(data) => {
    setisLoading(true)
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.CHANGE_AGENCY_DOMAIN}`
    const method = 'POST'
    const body = {
      contact_id : contactId,
      domain : domain
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    setisLoading(false)
    console.log(res)
  };

  useEffect(() => {
      setOpen(openChangeDomainModal)
  }, [openChangeDomainModal])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleOpenChangeDomainModel()
    setOpen(false);
  };

  const [personName, setPersonName] = React.useState([]);



  return (
    <div>
 <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change domain</DialogTitle>
        <DialogContent>
          <form onSubmit={()=>onSubmit()}>
          <TextField
            autoFocus
            margin="dense"
            id="contact_id"
            label="Contact id"
            type="text"
            name="contact_id"
            value={contactId}
            fullWidth
          />
            <TextField
            autoFocus
            margin="dense"
            id="domain"
            label="Domain"
            type="text"
            fullWidth
            onChange={(e)=>setisDomain(e.target.value)}
          />
      </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
            </Button>
            {
                isLoading ?
                <CircularProgress  />
                :
                <Button onClick={()=>onSubmit()} color="primary">
                Save
                </Button>
            }

        </DialogActions>
      </Dialog>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
