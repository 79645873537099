import React, {useState, useContext, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Stats from '../components/stats'
import getPermissions from '../permissions.ts'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import AgenciesDatatableC from '../components/agenciesDatatable';
import Agency from '../components/agency';
import AdminsDatatableC from '../components/adminsDatatable';
import { Button } from '@material-ui/core';
import {AuthContext} from "../contexts/auth.context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height : 'fit-content',
    backgroundColor : '#80808000'
  },
  appBar: {
   // justifyContent : 'space-between',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    //justifyContent : 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarItem: {
  flex : 1
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  bottomPush : {
    position: "fixed",
    bottom: 0,
    paddingLeft : "5px",
    textAlign: "center",
    paddingBottom: 10,
  }
}));

export default function MiniDrawer() {
  let authContext = useContext(AuthContext)

  const {
    logout,
    profile,
    canDo
  } = authContext


const permissions =  getPermissions()

const [actions, setactions] = useState([])

useEffect(() => {
   console.log('profile  changed')
    setactions([
      ...(canDo(permissions.READ_STATS)  ? [{ name : 'Stats' , route : 'stats', iconName : <EqualizerIcon />}] : []),
      ...(canDo(permissions.READ_AGENCY)  ? [{ name : 'Agencies' , route : 'agencies', iconName : <BusinessRoundedIcon />}] : []), 
      ...(canDo(permissions.READ_ADMIN)  ? [{ name : 'Admins' , route : 'admins', iconName : <SupervisorAccountRoundedIcon />}] : [])
  ])
}, [profile])

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Router>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <div edge="start" color="inherit" aria-label="menu" className={classes.toolbar} >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.toolbarItem}>
            Hello, {profile?.first_name}
          </Typography>
          
          <Typography variant="h6" className={classes.toolbarItem}>
            Leadific
          </Typography>
          <Button color="inherit" onClick={()=>logout()} className={classes.toolbarItem}>Logout</Button>
        </div>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
        {actions.map((item, index) => (
          <Link to={`/${item.route}`} className='drawerLink' key={index}>
            <ListItem button key={index}>
                                    <ListItemIcon> {item.iconName}</ListItemIcon>
                                    <ListItemText primary={item.name} />
            </ListItem>
            </Link>
          ))}
            <div className={classes.bottomPush}>
                <Typography>Leadific</Typography>
            </div>
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
            <Switch>
            <Route path="/agencies/*">
                <Agency></Agency>
                </Route>
                <Route path="/agencies">
                <AgenciesDatatableC></AgenciesDatatableC>
                </Route>
                <Route path="/admins">
                <AdminsDatatableC></AdminsDatatableC>
                </Route>
                <Route path="/stats">
                <Stats></Stats>
                </Route>
            </Switch>
      </main>
    </div>
</Router>
  );
}