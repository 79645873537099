import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import * as AdminRouter from '../routing/admin.json'
import { useForm } from 'react-hook-form';
import Snackbar from '../components/snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import {http} from '../helpers/http'


export default function LinkGhlAdminModal({openLinkGhlAdminModal, hundleopenLinkGhlAdminModal, fetchData, setadminToUpdate, adminToUpdate}) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = useState(false)
    const [ghl_id, setghl_id] = useState(null)

  useEffect(() => {
    setOpen(openLinkGhlAdminModal)
  }, [openLinkGhlAdminModal])

  const onSubmit =async(data) => {
    setisLoading(true)
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.UPDATE_ADMIN_GHL_ID}`
    const method = 'POST'
    const body = {
      ghl_id :  ghl_id,
      admin_id : adminToUpdate._id
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`,
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    setisLoading(false)
    console.log(res)
  };

  useEffect(() => {
      setOpen(openLinkGhlAdminModal)
  }, [openLinkGhlAdminModal])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleopenLinkGhlAdminModal()
    setOpen(false);
  };

  const [personName, setPersonName] = React.useState([]);



  return (
    <div>
 <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Link admin to GoHighLevel</DialogTitle>
        <DialogContent>
          <form onSubmit={()=>onSubmit()}>
          <DialogContentText>
             To link the admin, please enter a valid GoHighLevel user id.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="contact_id"
            label="GHL id"
            type="text"
            defaultValue=""
            name="ghl_id"
            defaultValue={adminToUpdate?.ghlAdminId}
            onChange={(e)=>setghl_id(e.target.value)}
            fullWidth
          />
      </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
            </Button>
            {
                isLoading ?
                <CircularProgress  />
                :
                <Button onClick={()=>onSubmit()} color="primary">
                Save
                </Button>
            }

        </DialogActions>
      </Dialog>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
