const getPermissions = () => {
    return {
        CREATE_ADMIN : "CREATE_ADMIN",
        READ_ADMIN : "READ_ADMIN",
        READ_AGENCY : "READ_AGENCY",
        UPDATE_ADMIN : "UPDATE_ADMIN",
        UPDATE_AGENCY_DOMAIN : "UPDATE_AGENCY_DOMAIN",
        DELETE_AGENCY : "DELETE_AGENCY",
        UPDATE_GHL_ADMIN : "UPDATE_GHL_ADMIN",
        GRANT_ADMIN_ACCESS : "GRANT_ADMIN_ACCESS",
        UPDATE_AGENCY  : "UPDATE_AGENCY",
        READ_STATS : "READ_STATS"
    }
}

export default getPermissions