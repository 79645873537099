import { Bar } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, useTheme, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
      textAlign : 'left',
      height: 'fit-content'
    },
    boldText: {
        fontWeight : 'bold'
    },
    pp : {
      overflowWrap: 'break-word'
    }
  });

export default function BarChart({title,data}) {
    
    const classes = useStyles();

    return (

        <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">

          <Bar data={data} />

          </Typography>
        </CardContent>
    </Card>
    )
}
