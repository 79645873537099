import { makeStyles, useTheme, } from '@material-ui/core/styles';
import BarChart from './barChart'
import * as StatsRouter from '../routing/stats.json'
import { useEffect, useState } from 'react';
import {http} from '../helpers/http'

const useStyles = makeStyles((theme)=>({
    head: {
      backgroundColor: "#3f51b54d",
      color:"black",
    },
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    addButton : {
    margin : '20px'
    },
    header : {
      backgroundColor : 'white',
      flex: 1,
      padding : '5px',
      marginBottom : '30px',
      borderRadius : '5px'
    },
    headerText : {
      fontWeight : 'bold',
      fontSize : '20px'
    },
    content : {
      display : 'flex',
      gap : '20px',
      flex : 1,
      flexWrap : 'wrap',
      justifyContent : 'center'
    },
    card : {
      flex : 1
    }
  }));
  

export default function Stats() {

  const [calls, setcalls] = useState([])
  const [sms, setsms] = useState([])  
  const [totalprice, settotalprice] = useState([])
  const [pfaxminutes, setpfaxminutes] = useState([])
  const [phonenumbers, setphonenumbers] = useState([])
  const [recordings, setrecordings] = useState([])
  const [pfaxpages, setpfaxpages] = useState([])
  const [transcriptions, settranscriptions] = useState([])
  const [pv, setpv] = useState([])

  const fetchData = async(category) =>{
      const url = `${process.env.REACT_APP_API_BASE_URL}${StatsRouter.USAGE}`
      const method = 'POST'
      const body = {
        category
      }
      const headers = {
          Authorization : `Bearer ${localStorage.getItem('token')}`
      }
      let res = await http({url , method, headers, body})

      if(res?.response?.success){
        switch(category) {
          case 'calls':
            setcalls(res?.response?.data)
            break;
            case 'sms':
              setsms(res?.response?.data)
              break;
            case 'totalprice':
              settotalprice(res?.response?.data)
              break;
            case 'pfax-minutes':
              setpfaxminutes(res?.response?.data)
              break;
            case 'phonenumbers':
              setphonenumbers(res?.response?.data)
              break;
            case 'recordings':
              setrecordings(res?.response?.data)
              break;
            case 'pfax-pages':
              setpfaxpages(res?.response?.data)
              break;
            case 'transcriptions':
              settranscriptions(res?.response?.data)
              break;
            case 'pv':
              setpv(res?.response?.data)
              break;
          default:
            // code block
        }
    }
  }
  
  
  useEffect(() => {
    let wantedStats= ['calls', 'sms', 'totalprice', 'pfax-minutes', 'phonenumbers','recordings','pfax-pages', 'transcriptions', 'pv']
    for(let i=0; i < wantedStats.length; i++){
      fetchData(wantedStats[i])
    }
  }, [])

    const classes = useStyles();

    return (
    < >
    <div className={classes.header}>
        <p className={classes.headerText} >
        Twillio
        </p>
    </div>
    <div className={classes.content}>
      {
        calls && <BarChart title={'Calls'} data={calls} className={classes.card}></BarChart>
      }
      {
          sms && <BarChart title={'Sms'} data={sms} className={classes.card}></BarChart>
      }
      {
          totalprice && <BarChart title={'Total'} data={totalprice} className={classes.card}></BarChart>
      }
      {
          phonenumbers && <BarChart title={'Phone numbers'} data={phonenumbers} className={classes.card}></BarChart>
      }
      {
          recordings && <BarChart title={'Recordings'} data={recordings} className={classes.card}></BarChart>
      }
      {
          transcriptions && <BarChart title={'Transcriptions'} data={transcriptions} className={classes.card}></BarChart>
      }
      {
          pv && <BarChart title={'Programmable Video'} data={pv} className={classes.card}></BarChart>
      }
      {
          pfaxpages && <BarChart title={'Pfax pages'} data={pfaxpages} className={classes.card}></BarChart>
      }
      {
          pfaxminutes && <BarChart title={'Pfax minutes'} data={pfaxminutes} className={classes.card}></BarChart>
      }
    </div>
    </>
    )
}
