import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    textAlign : 'left',
    height: 'fit-content'
  },
  boldText: {
      fontWeight : 'bold'
  },
  pp : {
    overflowWrap: 'break-word'
  }
});

export default function CustomCard({title, data}) {
    console.log('data')

    console.log(data)
  const classes = useStyles();

  return (
    <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">

            {
              data && data.length > 0 && data.map((item,i)=>(
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.pp}>
                  <span className={classes.boldText}> {item?.key} : </span>{
                    typeof item.value == 'object' && item.value.length > 0 ? 
                    JSON.stringify(item.value)
                    :
                  item?.value?.toString()
                  }
                    </Typography>
                ))
            }

          </Typography>
        </CardContent>
    </Card>
  );
}
