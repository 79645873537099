import React, { useEffect, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import * as AdminRouter from '../routing/admin.json'
import { useForm } from 'react-hook-form';
import Snackbar from '../components/snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import {http} from '../helpers/http'


export default function GrantGhlAdminAccessModal({openGhlGrantAdminAccess, hundleopenGhlGrantAdminAccess, fetchData, agencyId}) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    setOpen(openGhlGrantAdminAccess)
  }, [openGhlGrantAdminAccess])


  const onSubmit =async(data) => {
    setisLoading(true)
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.GRANT_GHL_ADMIN_ACCESS}`
    const method = 'POST'
    const body = {agency_id : agencyId}
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`,
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    setisLoading(false)
    console.log(res)
  };

  useEffect(() => {
      setOpen(openGhlGrantAdminAccess)
  }, [openGhlGrantAdminAccess])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleopenGhlGrantAdminAccess()
    setOpen(false);
  };

  const [personName, setPersonName] = React.useState([]);



  return (
    <div>
 <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Admin access.</DialogTitle>
        <DialogContent>
          <form onSubmit={(e)=>onSubmit()}>
          <DialogContentText>
            Are you sure you want to grant your self admin access to this location, for 30 mins ?
          </DialogContentText>
      </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
            </Button>
            {
                isLoading ?
                <CircularProgress  />
                :
                <Button onClick={(e)=>onSubmit()} color="primary">
                Continue
                </Button>
            }

        </DialogActions>
      </Dialog>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
