import React, { useState, useEffect } from 'react'
import * as AgencyRouter from '../routing/agency.json'
import {http} from '../helpers/http'
import { useParams } from 'react-router'
import CustomCard from './customCard'

export default function Agency({agency}) {
    const _id = useParams()[0]

    const agenciesDataInit = []

    const fetchData = async() =>{
        const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.GET_AGENCY}`
        const method = 'POST'
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        const body = {
            _id
        }
        let res = await http({url , method, headers, body})
        if(res?.response?.success){
            setagencyData(res.response.data)
        }
    }


    useEffect(() => {
        fetchData()
        }, [])

    const [agencyData, setagencyData] = useState(agency)
    console.log('agencyData')
    console.log(agencyData)

    let userData = []
    let contactData = []
    let locationData = []
    let parentLocationData = []
    let domainDataData = []


    if(agencyData && agencyData.users.length > 0 ){
        for (const key in  agencyData.users[0]) {
            userData.push({key : key, value :  agencyData.users[0][key]});
        }
    }

    if(agencyData && agencyData.contact ){
        for (const key in  agencyData.contact) {
            contactData.push({key : key, value :  agencyData.contact[key]});
        }
    }

    if(agencyData && agencyData.locations.length > 0 ){
        for (const key in  agencyData.locations[0]) {
            locationData.push({key : key, value :  agencyData.locations[0][key]});
        }
    }

    
    if(agencyData && agencyData.parentLocation ){
        for (const key in  agencyData.parentLocation) {
            parentLocationData.push({key : key, value :  agencyData.parentLocation[key]});
        }
    }
    

    if(agencyData && agencyData.domainData?.domain ){
        for (const key in  agencyData.domainData.domain) {
            domainDataData.push({key : key, value :  agencyData.domainData.domain[key]});
        }
    }
    return (
        agencyData ?
        <div className="agencyData">
            <CustomCard title='User' data={userData}></CustomCard>
            <CustomCard title='Location' data={locationData}></CustomCard>
            <CustomCard title='Parent location' data={parentLocationData}></CustomCard>
            <CustomCard title='Contact' data={contactData}></CustomCard>
            <CustomCard title='Domain' data={domainDataData}></CustomCard>
        </div>
        :
        <div>Loading ...</div>
    )
}
