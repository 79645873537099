import { createContext, useState, useEffect } from "react";
import {http} from '../helpers/http'
import * as StateRouter from '../routing/state.json'
export const FiltersContext = createContext()

const FiltersContextProvider = (props) => {

    const fetchStates = async() =>{
        const url = `${process.env.REACT_APP_API_BASE_URL}${StateRouter.ALL_STATES}`
        const method = 'POST'
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        let res = await http({url , method, headers})

        if(res?.response?.success){
            setStates(res.response.data)
        }

    }

    let [ states, setStates ] = useState([])


    useEffect(() => {
        fetchStates()
    }, [])



    return ( 
    <FiltersContext.Provider value={{states}}>
                {props.children}
    </FiltersContext.Provider>
    );
}

export default FiltersContextProvider;